<template>
  <div>
    <Loading :loading="isLoading"></Loading>
    <v-container class="mw-1150">
      <v-row class="mb-4">
        <v-col class="mt-3" cols="12">
          <v-text-field
            placeholder="พิมพ์คำที่ต้องการค้นหา"
            @keydown.enter="onsearch"
            v-model="keyword"
            rounded
            outlined
            hide-details
            dense
          >
            <template slot="append">
              <i
                @click="onsearch"
                class="mt-1 bi bi-search pointer"
              ></i> </template
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6" cols="12">
          <SearchTopic
            link="course"
            topics="หลักสูตรอบรม"
            name="course"
          ></SearchTopic>
        </v-col>
        <v-col
          v-for="(item, i) in list.training_course"
          :key="'training_course' + i"
          cols="12"
          style="padding: 10px"
        >
          <SearchPanel
            link="course"
            :width="150"
            :height="85"
            :model="item"
          ></SearchPanel>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="mt-5" v-if="list.event_new.length > 0" md="6" cols="12">
          <SearchTopic
            link="event-news"
            topics="กิจกรรมและข่าวสาร"
            name="event"
          ></SearchTopic>
        </v-col>
        <v-col
          style="padding: 10px"
          v-for="(item, j) in list.event_new"
          :key="'event_new' + j"
          cols="12"
        >
          <SearchPanel
            link="event-news"
            :width="150"
            :height="85"
            :model="item"
          ></SearchPanel>
        </v-col>
      </v-row>

      <v-row class="mb-12">
        <v-col
          class="mt-5"
          v-if="list.knowledge_media.length > 0"
          md="6"
          cols="12"
        >
          <SearchTopic
            link="media"
            topics="สื่อความรู้"
            name="media"
          ></SearchTopic>
        </v-col>
        <v-col
          v-for="(item, k) in list.knowledge_media"
          :key="item.id + k"
          cols="12"
        >
          <SearchPanel
            link="media"
            :width="63"
            :height="84"
            :model="item"
          ></SearchPanel>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SearchTopic from "../components/Search/SearchTopic.vue";
import SearchPanel from "../components/Search/SearchPanel.vue";
import Loading from "../components/Loading.vue";
import { mapState } from "vuex";
export default {
  components: {
    SearchTopic,
    SearchPanel,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      keyword: "",
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.Search.list,
    }),
  },
  async created() {
    this.onsearch();
  },
  methods: {
    async onsearch() {
      this.isLoading = true;
      await this.$store.dispatch("Search/get", this.keyword);
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
